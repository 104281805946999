import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
export default class UploadAdapter {
  constructor (loader) {
    // The file loader instance to use during the upload.
    this.loader = loader
  }

  // Starts the upload process.
  upload () {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this._initRequest()
        this._initListeners(resolve, reject, file)
        this._sendRequest(file)
      }))
  }

  // Aborts the upload process.
  abort () {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest () {
    const xhr = this.xhr = new XMLHttpRequest()

    try {
      const configElement = document.getElementById('config')
      var config = JSON.parse(configElement.innerHTML)
      config.baseApi = (config.baseApi) ? config.baseApi : '/api-manager'
    } catch (error) {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }

    xhr.open('POST', config.baseApi + 'image', true)
    xhr.setRequestHeader('Authorization', 'Bearer ' + getToken())
    xhr.responseType = 'json'
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners (resolve, reject, file) {
    const xhr = this.xhr
    const loader = this.loader
    const genericErrorText = `Couldn't upload file: ${file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText)
      }

      try {
        const configElement = document.getElementById('config')
        var config = JSON.parse(configElement.innerHTML)
        config.baseApi = (config.baseApi) ? config.baseApi.replace('/api-manager', '') : '/api-manager'
      } catch (error) {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
      resolve({
        // default: response.url
        default: config.baseApi + response.data.path
      })
    })

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  // Prepares the data and sends the request.
  _sendRequest (file) {
    // Prepare the form data.
    console.log(file)
    const data = new FormData()

    data.append('image', file)
    data.append('title', 'Imagen de Galería')
    data.append('alt', 'Descripción vacía')
    data.append('folder', 'images/editor')
    data.append('metadata', '{}')
    data.append('alt', 'Image')

    // Send the request.
    this.xhr.send(data)
  }
}
