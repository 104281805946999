<template>
  <div class="upload-image-component">
    <ckeditor  :editor="editor" v-model="content" :config="editorConfig" @input="update()" style="width: 100% !important;"></ckeditor>
  </div>
</template>

<script>

import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles'
import { Link } from '@ckeditor/ckeditor5-link'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { Font, FontFamily } from '@ckeditor/ckeditor5-font'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support'
import { ImageInsert, AutoImage, Image } from '@ckeditor/ckeditor5-image'
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed'
import { List, ListProperties } from '@ckeditor/ckeditor5-list'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table'
import UploadAdapter from '../../utils/uploadadapter.js'

function MyCustomUploadAdapterPlugin (editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new UploadAdapter(loader)
  }
}

export default {
  name: 'CKEditor',
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    setContent: {
      default: null,
      type: String,
      required: true
    }
  },
  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        plugins: [
          Essentials,
          Bold,
          Italic,
          Underline,
          Strikethrough,
          Code,
          Subscript,
          Superscript,
          Link,
          Paragraph,
          Heading,
          FindAndReplace,
          SourceEditing,
          Font,
          FontFamily,
          Highlight,
          GeneralHtmlSupport,
          Image,
          ImageInsert,
          AutoImage,
          MediaEmbed,
          List,
          ListProperties,
          BlockQuote,
          Table,
          TableToolbar],
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'sourceEditing',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'code',
            'subscript',
            'superscript',
            '|',
            'insertImage',
            'link',
            'mediaEmbed',
            'insertTable',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'blockQuote',
            'highlight',
            'findAndReplace'
          ]
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
        extraPlugins: [MyCustomUploadAdapterPlugin],
        EnterMode: 2,
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        }
      },
      content: this.setContent
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    update () {
      this.$emit('getContent', this.content)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
